import { makeStyles } from '@material-ui/styles';
import React, { useContext } from 'react';
import email from '../assets/images/EmailPreview.png';

const useStyles = makeStyles(() => ({
  name: {
    position: 'relative',
    top: '-575px',
    left: ' 72px',
    height: '16px',
  },
  url: {
    position: 'relative',
    top: '-460px',
    left: ' 170px',
    height: '12px',
  },
  username: {
    position: 'relative',
    top: '-455px',
    left: ' 170px',
    height: '12px',

  },
  password: {
    position: 'relative',
    top: '-435px',
    height: '12px',
    left: ' 170px',
  },
  text: {
    color: '#264270',
    fontWeight: 'bold',
  },
}));

const MockEmail = function (props) {
  const classes = useStyles();

  const values = useContext(props.Context);

  return (
    <div>
      <img src={email} height="700px" />
      <div className={classes.name}>
        <p className={classes.text} style={{ fontSize: '10pt' }}>{values[0]}</p>
      </div>
      <div className={classes.url}>
        <p className={classes.text} style={{ fontSize: '8pt' }}>
          https://
          {values[1]}
          /login/?next=/
        </p>
      </div>
      <div className={classes.username}>
        <p className={classes.text} style={{ fontSize: '8pt' }}>{values[2]}</p>
      </div>
      <div className={classes.password}>
        <p className={classes.text} style={{ fontSize: '8pt' }}>
          https://
          {values[1]}
          /restablecer_clave/
        </p>
      </div>
    </div>

  );
};

export default MockEmail;
