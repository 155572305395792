import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import {
  Grid,
  Paper,
  Container,
  Button,
  Typography,
  TextField,
  Checkbox,
  Link,
  Divider,
  FormControlLabel,
  Tooltip,
} from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { Redirect } from 'react-router';
import { CircularProgress } from '@mui/material';
import { logIn } from '../../apollo/sessionFunctions';
import logoManagermas from '../../assets/images/logo-manager.svg';
import { LOGIN_1, LOGIN_2 } from '../../gql/auth';

const DATA_TEMPLATE = {
  username: '',
  password: '',
};

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
    padding: '3rem',
    borderRadius: 15,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  logoManagermas: {
    [theme.breakpoints.down('xs')]: {
      width: 150,
    },
    width: 200,
  },
  containerLogo: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '1rem',
    },
    marginBottom: '2rem',
  },
  loginMessage: {
    marginBottom: '2rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  forgotPasswordLink: {
    alignSelf: 'center',
    fontSize: 'small',
  },
  tooltip: {
    fontSize: 20,
  },
}));

const LoginForm = function () {
  const classes = useStyles();
  const [loginData, setLoginData] = useState(DATA_TEMPLATE);
  const [loginMutation, { data, loading: loadingLogin }] = useMutation(LOGIN_1);
  const [staffMutation, { data: dataStaff }] = useMutation(LOGIN_2);
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLogin = async () => {
    try {
      await loginMutation({ variables: loginData });
    } catch (error) {
      alert(error);
    }
  };

  const handleCheckStaff = async () => {
    try {
      await staffMutation();
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    if (data) {
      logIn({ token: data.tokenAuth.token, refreshToken: data.tokenAuth.tokenEpiresIn });
      handleCheckStaff();
    }
    if (dataStaff) {
      setLoggedIn(true);
    }
  }, [data, dataStaff]);

  return (
    <Container maxWidth="xs" className={classes.container}>
      {loggedIn ? <Redirect to="/home" /> : null}
      <form>
        <Paper elevation={3} className={classes.paper}>
          <Grid container justifyContent="center" spacing={2}>
            <div className={classes.containerLogo}>
              <img className={classes.logoManagermas} src={logoManagermas} alt="logoManagermas" />
            </div>
            <div className={classes.loginMessage}>
              <Typography color="textSecondary" align="center">
                ¡Bienvenido,
                {' '}
                <b>ingresa a tu cuenta!</b>
              </Typography>
            </div>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={2} style={{ alignSelf: 'center' }} />
                <Grid item xs={10}>
                  <Tooltip
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    interactive
                    arrow
                    title={<Typography>Completa este campo</Typography>}
                  >
                    <TextField
                      name="username"
                      variant="outlined"
                      placeholder="Usuario"
                      label="Usuario"
                      fullWidth
                      onChange={(e) => setLoginData({ ...loginData, username: e.target.value })}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={2} style={{ alignSelf: 'center' }} />
                <Grid item xs={10}>
                  <Tooltip
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    interactive

                    arrow
                    title={<Typography>Completa este campo</Typography>}
                  >
                    <TextField
                      name="password"
                      variant="outlined"
                      placeholder="Clave"
                      label="Clave"
                      onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                      type="password"
                      fullWidth
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Grid container item xs={10} justifyContent="space-between">
                <FormControlLabel
                  control={<Checkbox color="primary" onChange={() => {}} name="rememberUser" />}
                  label={<Typography style={{ fontSize: 'small' }} variant="body2" color="textSecondary">Recordar usuario</Typography>}
                />
                <Link className={classes.forgotPasswordLink} color="primary" href="/restablecer_clave/">
                  Olvidé mi clave
                </Link>
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Grid item xs={10}>
                <Button
                  color="secondary"
                  variant="contained"
                  disabled={loadingLogin}
                  endIcon={loadingLogin ? <CircularProgress size={20} /> : null}
                  fullWidth
                  onClick={() => handleLogin()}
                >
                  <b>INICIAR SESIÓN</b>
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: '2rem' }} />
            </Grid>
            <Grid container item xs={12} justifyContent="space-between">
              <Typography color="textSecondary" style={{ fontSize: '11px' }} variant="body2">¿Necesitas ayuda?</Typography>
              <Link
                style={{ fontSize: 12, color: '#1A68A7' }}
                target="_blank"
                rel="noopener noreferrer"

              >
                Contacta con Soporte Manager
              </Link>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Container>
  );
};

LoginForm.propTypes = {
  width: PropTypes.string.isRequired,
};

export default withWidth()(LoginForm);
